module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.chaloupkakteraexistuje.cz/graphql","verbose":true,"debug":{"preview":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"timeout":60000,"requestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"previewRequestConcurrency":5},"type":{"MediaItem":{"localFile":{"requestConcurrency":2,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143997143-1","anonymize":true,"respectDNT":true,"head":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Barlow:400,700:latin-ext&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chaloupka, která existuje","short_name":"Chaloupka","start_url":"/","icon":"src/images/icon-512x512.png","cache_busting_mode":"name","background_color":"#ffffff","theme_color":"#224047","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d4a05e9f434c72d0fa33909a6bbc4b7d"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
