exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-recenze-js": () => import("./../../../src/pages/recenze.js" /* webpackChunkName: "component---src-pages-recenze-js" */),
  "component---src-pages-vseobecne-podminky-js": () => import("./../../../src/pages/vseobecne-podminky.js" /* webpackChunkName: "component---src-pages-vseobecne-podminky-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-room-type-gallery-js": () => import("./../../../src/templates/room-type-gallery.js" /* webpackChunkName: "component---src-templates-room-type-gallery-js" */),
  "component---src-templates-room-type-js": () => import("./../../../src/templates/room-type.js" /* webpackChunkName: "component---src-templates-room-type-js" */),
  "component---src-templates-room-type-pricelist-js": () => import("./../../../src/templates/room-type-pricelist.js" /* webpackChunkName: "component---src-templates-room-type-pricelist-js" */),
  "component---src-templates-rooms-list-js": () => import("./../../../src/templates/rooms-list.js" /* webpackChunkName: "component---src-templates-rooms-list-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

